<template>
  <v-container>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-form lazy-validation>
          <v-text-field
            v-model="cliente.nome"
            label="Nome"
          />

          <v-text-field
            v-model="cliente.status"
            label="Status"
          />

          <v-text-field
            v-model="cliente.codigoStyllus"
            label="Código Styllus"
          />

          <v-text-field
            v-model="cliente.tipo"
            label="Tipo"
          />

          <v-text-field
            v-model="cliente.cpf"
            label="CPF"
          />

          <v-text-field
            v-model="cliente.identidade"
            label="Identidade"
          />

          <v-text-field
            v-model="cliente.orgaoExpedidor"
            label="Órgão Expedidor"
          />

          <v-text-field
            v-model="cliente.dataNascimento"
            label="Data de Nascimento"
            name="dataNascimento"
          />

          <v-text-field
            v-model="cliente.contatoSMS"
            label="Telefone com DDD"
          />

          <v-text-field
            v-model="cliente.nomeDoPai"
            label="Nome do Pai"
          />

          <v-text-field
            v-model="cliente.nomeDaMae"
            label="Nome da Mãe"
          />
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    props: {
      cliente: {
        type: Object,
        required: true,
      },
    },
  }
</script>

<style>

</style>
