<template>
  <v-container fluid>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-card>
          <v-card-title>
            Cashbacks Gerados
            <v-spacer />
            <v-btn
              color="primary"
              fab
              dark
              @click="dialog = true"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="cashbacks"
            :options.sync="options"
            :server-items-length="total"
            class="elevation-1"
            :footer-props="footer_props"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.cashbackId"
                >
                  <td>{{ item.pedidoId ? `#${item.pedidoId}` : 'Manual' }}</td>
                  <td>{{ item.cashbackId }}</td>
                  <td>{{ item.valor | currency }}</td>
                  <td>{{ item.createdAt | timestamp }}</td>
                  <td>{{ item.expiresAt | timestamp }}</td>
                  <td>
                    <v-icon
                      :disabled="item.pedidoId > 0"
                      color="error"
                      @click="destroy(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
        <v-card class="mt-4">
          <v-card-title>Cashbacks Utilizados</v-card-title>
          <v-data-table
            :headers="headersUtilizados"
            :items="utilizados"
            :options.sync="optionsUtilizados"
            :server-items-length="totalUtilizados"
            class="elevation-1"
            :footer-props="footer_props"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.cashbackHistoricoId"
                >
                  <td>{{ item.pedidoId ? `#${item.pedidoId}` : 'Manual' }}</td>
                  <td>{{ item.cashbackId }}</td>
                  <td>{{ item.valorUtilizado | currency }}</td>
                  <td>{{ item.pedido.dataPedido | timestamp }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card v-if="!destroying">
        <v-card-title class="text-h5">
          Novo cashback
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="cashback.valor"
            label="Valor Total"
            name="valor"
            type="number"
            step="0.01"
          />

          <v-text-field
            v-if="cashback.cashbackId"
            v-model="cashback.saldo"
            label="Saldo disponível"
            name="valor"
            type="number"
            step="0.01"
          />

          <v-text-field
            v-model="cashback.expiresAt"
            label="Expira em"
            name="expiresAt"
            type="date"
          />
        </v-card-text>

        <v-card-actions>
          <div class="flex-grow-1" />
          <v-btn
            outlined
            class="ml-4"
            large
            color="success"
            dark
            @click="submit()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title class="text-h5">
          Você tem certeza?
        </v-card-title>
        <v-card-text>
          O cashback, e todo o histórico relacionado a ele (se houver), será
          <strong>deletado</strong>.
          <br>Essa ação é irreversível.
          <br>Deseja prosseguir?
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1" />
          <v-btn
            outlined
            class="ml-4"
            large
            color="error"
            dark
            @click="dialog = false"
          >
            Espere, vou verificar
          </v-btn>

          <v-btn
            outlined
            class="ml-4"
            large
            color="success"
            dark
            @click="confirmDelete()"
          >
            Sim, prossiga
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  export default {
    props: {
      codigoStyllus: {
        type: Number,
        required: true,
      },
      clienteId: {
        type: Number,
        required: true,
      },
    },
    data: () => ({
      dialog: false,
      destroying: false,
      cashback: {},
      footer_props: {
        'items-per-page-options': [5, 10, 15, 20],
      },
      cashbacks: [],
      utilizados: [],
      headers: [
        { text: 'Pedido #', sortable: false },
        { text: 'Cashback #', sortable: false },
        { text: 'Valor', sortable: false },
        { text: 'Gerado em', sortable: false },
        { text: 'Expira em', sortable: false },
        { text: ' ', sortable: false },
      ],
      headersUtilizados: [
        { text: 'Pedido #', sortable: false },
        { text: 'Cashback #', sortable: false },
        { text: 'Valor Utilizado', sortable: false },
        { text: 'Utilizado em', sortable: false },
      ],
      total: 0,
      totalUtilizados: 0,
      options: {
        sortBy: [],
        sortDesc: [],
      },
      optionsUtilizados: {
        sortBy: [],
        sortDesc: [],
      },
      filter: {
        PerPage: 25,
        Page: 1,
        SortAscending: true,
        Term: '',
      },
      filterUtilizados: {
        PerPage: 25,
        Page: 1,
        SortAscending: true,
        Term: '',
      },
    }),
    watch: {
      options: {
        handler () {
          this.load()
        },
        deep: true,
      },
      optionsUtilizados: {
        handler () {
          this.loadUtilizados()
        },
        deep: true,
      },
    },
    methods: {
      loadUtilizados () {
        this.filterUtilizados.PerPage = this.optionsUtilizados.itemsPerPage
        this.filterUtilizados.Page = this.optionsUtilizados.page
        this.filterUtilizados.ClienteId = this.clienteId
        const query = JSON.stringify(this.filterUtilizados)
        this.$http
          .get('/cashback-historico', { params: { query } })
          .then((resp) => {
            this.utilizados = resp.data.rows
            this.totalUtilizados = resp.data.total
          })
      },
      load () {
        this.filter.PerPage = this.options.itemsPerPage
        this.filter.Page = this.options.page
        this.filter.clienteId = this.clienteId
        const query = JSON.stringify(this.filter)
        this.$http.get('/cashback', { params: { query } }).then((resp) => {
          this.cashbacks = resp.data.rows
          this.total = resp.data.total
        })
      },
      submit () {
        this.cashback.clienteId = this.clienteId
        this.cashback.valor = parseFloat(this.cashback.valor)
        if (this.cashback.cashbackId) {
          this.put()
        } else {
          this.post()
        }
      },
      post () {
        this.$http.post('/cashback', this.cashback).then((resp) => {
          this.$toast.success('Operação realizada com sucesso')
          this.load()
          this.dialog = false
          this.cashback = {}
        })
      },
      edit (item) {
        this.cashback = { ...item }
        this.cashback.expiresAt = item.expiresAt.substring(0, 10)
        this.dialog = true
      },
      put () {
        this.$http.put('/cashback', this.cashback).then((resp) => {
          this.$toast.success('Operação realizada com sucesso')
          this.load()
          this.dialog = false
          this.cashback = {}
        })
      },
      destroy (item) {
        this.cashback = { ...item }
        this.destroying = true
        this.dialog = true
      },
      confirmDelete () {
        this.$http
          .delete(`/cashback/${this.cashback.cashbackId}`)
          .then((resp) => {
            this.$toast.success('Operação realizada com sucesso')
            this.dialog = false
            this.destroying = false
            this.load()
          })
      },
    },
  }
</script>
