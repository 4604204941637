<template>
  <v-container>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-form lazy-validation>
          <v-text-field
            v-model="endereco.logradouro"
            label="Endereço"
          />

          <v-text-field
            v-model="endereco.numero"
            label="Número"
          />

          <v-text-field
            v-model="endereco.complemento"
            label="Complemento"
          />

          <v-text-field
            v-model="endereco.bairro"
            label="Bairro"
          />

          <v-text-field
            v-model="endereco.cep"
            label="CEP"
          />

          <v-autocomplete
            v-model="endereco.cidade.estado.id"
            label="Estado"
            :items="estados"
            item-text="display"
            item-value="id"
          />

          <v-autocomplete
            v-model="endereco.cidade.id"
            label="Cidade"
            :items="cidades"
            item-text="display"
            item-value="id"
          />
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    props: {
      type: {
        type: String,
        required: true,
      },
      cliente: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      endereco: {
        cidade: {
          estado: {},
        },
      },
      cidades: [],
      estados: [],
    }),
    watch: {
      'endereco.cidade.estado.id' (value) {
        if (value) {
          this.$http
            .get(`/endereco/cidade?estadoId=${value}`)
            .then(resp => {
              this.cidades = resp.data
            })
        } else {
          this.cidades = []
        }
      },
    },
    mounted () {
      this.$http
        .get('/endereco/estado')
        .then(resp => {
          this.estados = resp.data
        })
      this.$http
        .get(`/endereco/${this.type}?clienteId=${this.cliente.id}`)
        .then(resp => {
          this.endereco = resp.data
        })
    },
  }
</script>

<style>

</style>
