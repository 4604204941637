<template>
  <v-container>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-form lazy-validation>
          <v-text-field
            v-model="cliente.nome"
            label="Nome"
          />

          <v-text-field
            v-model="cliente.email"
            label="E-mail"
          />

          <v-text-field
            v-model="cliente.codigoStyllus"
            label="Código Styllus"
          />

          <v-text-field
            v-model="cliente.perfil.nome"
            label="Perfil"
          />

          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-btn
                color="secondary"
                block
                @click="resetPassword"
              >
                Resetar senha
              </v-btn>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-btn
                color="primary"
                block
                @click="check"
              >
                Verificar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    props: {
      cliente: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      nameRules: [
        v => !!v || 'Nome é obrigatório',
      ],
      passwordRules: [
        v => !!v || 'Senha é obrigatório',
      ],
      emailRules: [
        v => !!v || 'E-mail é obrigatório',
        v => /.+@.+\..+/.test(v) || 'E-mail precisa ser válido',
      ],
    }),
    methods: {
      resetPassword () {
        this.$http.put('/reset-password/' + this.$route.params.id)
          .then(() => {
            this.$toast.success('Operação realizada com sucesso')
          })
      },
      async check () {
        try {
          await this.$http.put(`/Cliente/${this.$route.params.id}/Check`)
          this.$toast.success('Operação realizada com sucesso')
          this.$emit('onReload')
        } catch (e) {
          // console.error(e)
          this.$toast.error('Um erro aconteceu!')
        }
      },
    },
  }
</script>

<style>

a {
  text-decoration: none;
  font-weight: bold;
}

</style>
