<template>
  <v-container fluid>
    <v-row
      align="center"
      justify="center"
    >
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="pedidos"
          :options.sync="options"
          :server-items-length="total"
          :footer-props="footer_props"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="item in items"
                :key="item.id"
              >
                <td>
                  <router-link :to="`/pages/pedidos/detail/${item.id}`">
                    {{ item.id }}
                  </router-link>
                </td>
                <td>{{ item.totalLiquido | currency }}</td>
                <td>{{ item.dataPedido | timestamp }}</td>
                <td>{{ item.statusAtual.status ? item.statusAtual.status.nomeStatus : 'Status desconhecido' }}</td>
                <td>{{ item.tipoPagamento.nome.substring(0, 7) }}</td>
                <td>{{ item.statusPgto }}</td>
                <td>
                  {{ renegociado(item) ? "SIM" : "NÂO" }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    props: {
      clienteId: {
        type: Number,
        required: true,
      },
    },
    data () {
      return {
        pedidos: [],
        total: 0,
        headers: [
          { text: 'ID', sortable: false },
          { text: 'Valor', sortable: false },
          { text: 'Data', sortable: false },
          { text: 'Status', sortable: false },
          { text: 'Tp. Pgto', sortable: false },
          { text: 'Status Pgto', sortable: false },
          { text: 'Possui negociação?', sortable: false },
        ],
        footer_props: {
          'items-per-page-options': [5, 10, 15, 20],
        },
        filter: {
          PerPage: 25,
          Page: 1,
          IncluirCobrancas: true,
          Tipo: 1,
        },
        options: {
          sortBy: [],
          sortDesc: [],
        },
        watch: {
          options: {
            handler () {
              this.load()
            },
            deep: true,
          },
        },
      }
    },
    mounted () {
      this.load()
    },
    methods: {
      renegociado (pedido) {
        return pedido.cobrancasDevedoresPedidos && (pedido.cobrancasDevedoresPedidos.length > 0)
      },
      load () {
        this.filter.PerPage = this.options.itemsPerPage
        this.filter.Page = this.options.page
        this.filter.ClienteId = this.clienteId
        const query = JSON.stringify(this.filter)
        this.$http.get('/pedido', { params: { query } })
          .then(resp => {
            this.pedidos = resp.data.rows
            this.total = resp.data.total
          })
      },
    },
  }
</script>
