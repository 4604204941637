<template>
  <v-container fluid>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-card>
          <v-card-title>
            Crédito
            <v-spacer />
            <v-btn
              color="primary"
              fab
              dark
              @click="dialog = true"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="creditos"
            :options.sync="options"
            :server-items-length="total"
            class="elevation-1"
            :footer-props="footer_props"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  :class="{ 'debito': item.valor < 0, 'credito': item.valor > 0 }"
                >
                  <td>{{ item.id }}</td>
                  <td>{{ item.valor | currency }}</td>
                  <td>{{ item.saldo | currency }}</td>
                  <td>{{ tipoDo(item) }}</td>
                  <td>{{ item.usuarioAnalise }}</td>
                  <td>{{ item.createdAt | timestampfix }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      scrollable
      width="500"
    >
      <v-card>
        <v-card-title>Alterar Limite de Crédito</v-card-title>
        <v-card-text>
          <h4>Cliente: {{ cliente.nome || '' }}</h4>
          <v-text-field
            v-model="cliente.NovoValor"
            label="Novo limite"
            name="novoLimite"
            type="number"
            :rules="rules"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="definirNovoValor({
              ClienteId: cliente.id,
              NovoLimite: parseFloat(cliente.NovoValor)
            })"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  export default {
    props: {
      cliente: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    data () {
      return {
        dialog: false,
        footer_props: {
          'items-per-page-options': [5, 10, 15, 20],
        },
        creditos: [],
        headers: [
          { text: 'ID #', sortable: false },
          { text: 'Movimentação', sortable: false },
          { text: 'Saldo', sortable: false },
          { text: 'Tipo', sortable: false },
          { text: 'Alterado por', sortable: false },
          { text: 'Data', sortable: false },
        ],
        total: 0,
        options: {
          sortBy: [],
          sortDesc: [],
        },
        filter: {
          PerPage: 25,
          Page: 1,
          SortAscending: true,
          Term: '',
        },
        rules: [
          v => !!v || 'O novo valor é obrigatório',
          v => (v && v <= 10000) || 'Limite máximo R$ 10.000,00',
          v => (v && v >= 0) || 'Limite de crédito não pode ser negativo.',
        ],
      }
    },
    watch: {
      options: {
        handler () {
          this.load()
        },
        deep: true,
      },
    },
    methods: {
      load () {
        this.filter.PerPage = this.options.itemsPerPage
        this.filter.Page = this.options.page
        this.filter.ClienteId = this.cliente.id
        const query = JSON.stringify(this.filter)
        this.$http.get('/credito/movimentacao', { params: { query } })
          .then(resp => {
            this.creditos = resp.data.rows
            this.total = resp.data.total
          })
      },
      definirNovoValor (payload) {
        if (this.cliente.NovoValor >= 0 && this.cliente.NovoValor <= 10000) {
          this.$http.post('/credito/limite', payload)
            .then(resp => {
              this.dialog = false
              this.$toast.success('Operação realizada com sucesso')
              this.load()
            })
            .catch(err => {
              let msg = 'Algo deu errado :('
              if (err.response && err.response.data) {
                msg = err.response.data.message
              }
              this.$toast.error(msg)
            })
        } else {
          const msg = 'Valor do crédito inválido'
          this.$toast.error(msg)
        }
        this.cliente.NovoValor = null
      },
      tipoDo (item) {
        switch (item.tipo) {
          case 1:
            return 'Crédito'
          case 2:
            return 'Débito'
          case 3:
            return 'Manual via Gestor'
          case 4:
            return 'Sistema'
          default:
            break
        }
      },
    },
  }
</script>

<style >
.credito {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.debito {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
