<template>
  <v-container>
    <v-sheet
      oulined
      elevation="1"
      :class="[cliente.integrado ? 'cursor' : '']"
      @click="novaIntegracao()"
    >
      <v-list-item>
        <v-list-item-avatar>
          <v-icon
            class="success lighten-1"
            :class="[cliente.integrado ? 'success' : 'warning']"
            dark
          >
            mdi-antenna
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-if="cliente.integrado">
            Solicitar nova integração
          </v-list-item-title>
          <v-list-item-title v-else>
            Aguardando a integração
          </v-list-item-title>
          <v-list-item-subtitle v-if="cliente.dataIntegracao">
            Última integração: {{ timestamp(cliente.dataIntegracao) }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-sheet>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-card>
          <v-tabs slider-color="primary">
            <v-tab ripple>
              Dados do Usuário
            </v-tab>
            <v-tab ripple>
              Dados Pessoais
            </v-tab>
            <v-tab ripple>
              Endereço de Entrega
            </v-tab>
            <v-tab ripple>
              Endereço de Cobrança
            </v-tab>
            <v-tab
              v-if="possuiPermissao('cashback')"
              ripple
            >
              Cashback
            </v-tab>
            <v-tab
              v-if="possuiPermissao('credito')"
              ripple
            >
              Crédito
            </v-tab>
            <v-tab ripple>
              Cupom (Revenda Virtual)
            </v-tab>
            <v-tab ripple>
              Pedidos
            </v-tab>
            <v-tab-item>
              <v-card flat>
                <user-form
                  :cliente="cliente"
                  @onReload="load"
                />
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <dados-pessoais :cliente="cliente" />
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <endereco
                  type="shipping"
                  :cliente="cliente"
                />
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <endereco
                  type="billing"
                  :cliente="cliente"
                />
              </v-card>
            </v-tab-item>
            <v-tab-item v-if="possuiPermissao('cashback')">
              <v-card flat>
                <cashback
                  :codigo-styllus="cliente.codigoStyllus"
                  :cliente-id="cliente.id"
                />
              </v-card>
            </v-tab-item>
            <v-tab-item v-if="possuiPermissao('credito')">
              <v-card flat>
                <credito :cliente="cliente" />
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <cupom :codigo-styllus="cliente.codigoStyllus" />
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <pedidos :cliente-id="cliente.id" />
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import moment from 'moment'

  import DadosPessoais from '../components/DadosPessoais'
  import Endereco from '../components/Endereco'
  import Cashback from '../components/Cashback'
  import Credito from '../components/Credito'
  import Cupom from '../components/Cupom'
  import Pedidos from '../components/Pedidos'
  import UserForm from '../components/UserForm'

  export default {
    components: {
      DadosPessoais,
      Endereco,
      Cashback,
      Credito,
      Cupom,
      Pedidos,
      UserForm,
    },
    data: () => ({
      cliente: {
        perfil: {},
      },
    }),
    mounted () {
      if (this.$route.params.id) {
        this.load()
      }
    },
    methods: {
      load () {
        this.$http.get(`/cliente/${this.$route.params.id}`).then(resp => {
          this.cliente = resp.data
        })
      },
      possuiPermissao (modulo) {
        return this.$store.getters.user.roles.indexOf(modulo) !== -1
      },
      novaIntegracao () {
        if (this.cliente.integrado === false) return
        var payload = {
          Id: this.$route.params.id,
        }
        this.$http.post(`/cliente/${this.$route.params.id}/integrar`, payload)
          .then(resp => {
            this.load()
          })
      },
      timestamp (datestring) {
        if (datestring) return moment(datestring).format('DD/MM/YYYY HH:mm')
        else return null
      },
    },
  }
</script>

<style>
.cursor {
  cursor: pointer;
}
</style>
