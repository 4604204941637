<template>
  <v-container fluid>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-form
          v-if="cupom"
          lazy-validation
        >
          <v-text-field
            v-model="cupom.texto"
            label="Cupom"
            name="texto"
            type="text"
          />
          <v-btn
            color="primary"
            @click="submit"
          >
            Salvar
          </v-btn>
        </v-form>
        <v-alert
          v-else
          border="right"
          color="blue-grey"
          dark
        >
          A revendedora ainda não solicitou o cupom
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    props: {
      codigoStyllus: {
        type: Number,
        required: true,
      },
    },
    data: () => ({
      cupom: {},
      rules: [
        v => !!v || 'Esse campo é obrigatório',
      ],
    }),
    mounted () {
      this.load()
    },
    methods: {
      load () {
        const payload = {
          codigoStyllus: this.codigoStyllus,
          page: 1,
          perPage: 1,
        }
        this.$http.get('/cupom?query=' + JSON.stringify(payload))
          .then(resp => {
            if (resp.data && resp.data.rows) {
              this.cupom = resp.data.rows[0]
            }
          })
      },
      submit () {
        this.$http.put('/cupom', this.cupom)
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
          })
      },
    },
  }
</script>
